import { Divider, Skeleton } from 'antd';
import { NoteDto } from '@medone/medonehp-api-client';

type Props = {
    note?: NoteDto;
};

const NotePreview = ({ note }: Props) => {
    return (
        <Skeleton loading={!note}>
            <div className="note-body">
                <div className="mb-1">
                    <div>
                        <strong>History of Present Illness</strong>
                    </div>

                    {note?.historyOfPresentIllness}
                </div>

                <Divider className="m-1" />

                {note?.problemList.map((problem, idx) => (
                    <div key={idx} className="mb-1">
                        <div>
                            <strong>{problem.preferredTerm}</strong>
                        </div>

                        {problem.description}
                    </div>
                ))}

                <div>
                    <strong>Signed by:</strong> {note?.signedByUserName}
                </div>
            </div>
        </Skeleton>
    );
};

export default NotePreview;
