import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    CensusBadgeDto,
    CensusBadgeRequestDto,
    CensusClient,
    CensusDto,
    CensusFiltersDto,
    CensusGroupDto,
    ScheduleDto,
    SchedulesClient,
    PatientIntakesClient,
    DischargeDispositionChangeDto,
    UndoDischargeDto,
    VisitDto,
    RequiredVisitRequestDto,
    MedicalSummaryDto,
    PatientIntakeVisitsDto,
    RequiredVisitResultDto,
    NotesClient,
} from '@medone/medonehp-api-client';
import { orderBy, uniq } from 'lodash';
import moment from 'moment';

import { initialState, CensusState, censusBadgesAdapter, visitDuesAdapter, specialtiesAdapter } from './models';
import { Axios } from '../../../../shared/common/http';
import { handleError } from '../../../../shared/common/HandleErrors';
import { AppDispatch, AppThunk, RootState } from '../../../../shared/store';

import { FacilityQuickNotesCountUpdatedDto } from '../models';
import { fetchFacilities } from '../admin/slice.facilities';
import { fetchRegions } from '../admin/slice.regions';
import { fetchFilter, saveFilter } from '../../users/slice';
import { getCleanMedicalSummaryFields } from '../../../../shared/common/helpers';
import { fetchTotalAbandoned } from '../abandoned/slice';

import { clearNoteData, reducers as notesReducers, setCurrentCensus, setCurrentNote, setCurrentNoteType } from './slice.notes';
import { applyPatientIntakeLock, reducers as patientIntakesReducers } from './slice.patient-intakes';
import { fetchPatientNotes, reducers as patientNotesReducers } from './slice.patient-notes';
import { fetchPatient, fetchPccPatient, reducers as patientsReducers, togglePatientDrawer } from './slice.patients';
import { reducers as patientLabsReducers } from './slice.patient-labs';
import { reducers as stagedNoteReducers } from './slice.staged-notes';
import { reducers as returnToHospitalReducers } from './slice.patient-return-to-hospital';
import { fetchSpecialties, reducers as specialtiesReducers } from './slice.specialties';
import { reducers as hospiceIntakeReducers } from './slice.hospice-intakes';
import { getStoredCollapsedState, LocalStoragePageKeys } from '../../../../shared/common/helpers/page-state-helpers';
import { setCurrentUnsignedNote, setCurrentUnsignedNoteType } from '../unsigned-notes/slice';

export const censusSlice = createSlice({
    name: 'census',
    initialState,
    reducers: {
        setError: (state: CensusState, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
            state.patientLabProcessing = false;
            state.pccPatientLoading = null;
        },

        setCensus: (state: CensusState, action: PayloadAction<CensusGroupDto[]>) => {
            state.errorMessage = null;
            state.census = action.payload;
        },

        updateCensus: (state: CensusState, action: PayloadAction<CensusDto>) => {
            const { admittedToId, patientIntakeId } = action.payload;

            const newCensus = state.census.map((censusItems) => {
                // Only attempt the update if the incoming admitted facility matches a selected census location
                if (censusItems.admittedToId === admittedToId) {
                    const index = censusItems.items.findIndex((x) => x.patientIntakeId === patientIntakeId);
                    let newItems = [...censusItems.items];

                    if (index !== -1) {
                        newItems = newItems.map((item) => {
                            if (item.patientIntakeId === patientIntakeId) {
                                return { ...item, ...action.payload } as CensusDto;
                            }

                            return item;
                        });
                    } else {
                        // Validate the facility
                        newItems.push(action.payload);

                        // Match ordering from server side logic
                        newItems = orderBy(newItems, ['lastName', 'firstName', 'middleName', (x) => x.dateOfBirth?.format('L')], ['asc', 'asc', 'asc', 'asc']);
                    }

                    return { ...censusItems, items: newItems } as CensusGroupDto;
                }

                return censusItems;
            });

            state.census = newCensus;
        },

        setCensusFilters: (state: CensusState, action: PayloadAction<CensusFiltersDto>) => {
            state.censusFilters = action.payload;
        },

        updateCensusRoomNumber: (state: CensusState, action: PayloadAction<CensusDto>) => {
            const { patientIntakeId } = action.payload;

            const newCensus = state.census.map((censusItems) => {
                const newItems = censusItems.items.map((censusItem) => {
                    if (censusItem.patientIntakeId === patientIntakeId) {
                        return { ...censusItem, ...action.payload } as CensusDto;
                    }

                    return censusItem;
                });

                return { ...censusItems, items: newItems } as CensusGroupDto;
            });

            state.census = newCensus;
        },

        updateUnacknowledgedCount: (state: CensusState, action: PayloadAction<FacilityQuickNotesCountUpdatedDto[]>) => {
            action.payload.forEach((payload) => {
                const { admittedToId, quickNotesUnacknowledgedCount, quickNotesNeedsFollowupCount } = payload;

                const newCensus = state.census.map((censusItems) => {
                    if (censusItems.admittedToId === admittedToId) {
                        return { ...censusItems, quickNotesUnacknowledgedCount, quickNotesNeedsFollowupCount } as CensusGroupDto;
                    }

                    return censusItems;
                });

                state.census = newCensus;
            });
        },

        setSchedule: (state: CensusState, action: PayloadAction<ScheduleDto>) => {
            state.schedules = [...state.schedules, action.payload];
        },

        setVisit: (state: CensusState, action: PayloadAction<VisitDto>) => {
            if (action.payload != null) {
                const newVisits = [...state.visits];
                const index = state.visits.findIndex((x) => x.id === action.payload.id);

                if (index === -1) {
                    newVisits.push(action.payload);
                } else {
                    newVisits.splice(index, 1, action.payload);
                }

                state.visits = newVisits;
            }
        },

        setVisits: (state: CensusState, action: PayloadAction<PatientIntakeVisitsDto>) => {
            if (action.payload != null) {
                const {
                    patientIntakeId,
                    visits,
                    isHistoryAndPhysicalButtonVisible,
                    isWoundButtonVisible,
                    isInitialPMRButtonVisible,
                    userIsPMRProvider,
                    userIsPhysicianScribe,
                    isAwvButtonVisible,
                    isProgressButtonVisible,
                    isDischargeButtonVisible,
                } = action.payload;

                const newVisits = [...state.visits];

                visits.forEach((visit) => {
                    const index = state.visits.findIndex((x) => x.id === visit.id);

                    if (index === -1) {
                        newVisits.push(visit);
                    } else {
                        newVisits.splice(index, 1, visit);
                    }
                });

                const visitButtons = [...state.visitButtons].filter((x) => x.patientIntakeId !== patientIntakeId);

                visitButtons.push({
                    patientIntakeId: patientIntakeId,
                    isHistoryAndPhysicalButtonVisible,
                    isWoundButtonVisible,
                    isInitialPMRButtonVisible,
                    userIsPMRProvider,
                    userIsPhysicianScribe,
                    isAwvButtonVisible,
                    isProgressButtonVisible,
                    isDischargeButtonVisible,
                });

                state.visits = newVisits;
                state.visitButtons = visitButtons;
            }
        },

        resetVisits: (state: CensusState, action: PayloadAction<void>) => {
            state.visits = [];
        },

        storeCensusGroup: (state: CensusState, action: PayloadAction<CensusGroupDto>) => {
            const { admittedTo, admittedToId, patientIntakeIds } = action.payload;
            const index = state.censusGroupsToRefresh.findIndex((x) => x.admittedToId === admittedToId);

            if (index === -1) {
                const newArray = [...state.censusGroupsToRefresh];

                newArray.push({ admittedTo, admittedToId, patientIntakeIds } as CensusGroupDto);

                state.censusGroupsToRefresh = uniq(newArray);
            }
        },

        setPendingMedicalSummaryChanges: (state: CensusState, action: PayloadAction<MedicalSummaryDto>) => {
            // TODO: Remove this once we re-work how medical summary changes are tracked
            // possibly by moving to a normalized store set instead of medical summary data
            // on the intake/note, etc
            if (action.payload) {
                state.pendingMedicalSummaryChanges = true;
                state.pendingMedicalSummaryFields = action.payload;
            } else {
                state.pendingMedicalSummaryChanges = false;
                state.pendingMedicalSummaryFields = null;
            }
        },

        setBadgeData: (state: CensusState, action: PayloadAction<CensusBadgeDto[]>) => {
            censusBadgesAdapter.setMany(state.badges, action.payload);
        },

        setVisitDues(state: CensusState, action: PayloadAction<RequiredVisitResultDto[]>) {
            visitDuesAdapter.setMany(state.visitDues, action.payload);
        },

        clearVisitDuesForIntake(state: CensusState, action: PayloadAction<number>) {
            visitDuesAdapter.updateOne(state.visitDues, { id: action.payload, changes: { dueDates: [] } });
        },

        ...patientIntakesReducers,
        ...patientsReducers,
        ...notesReducers,
        ...patientNotesReducers,
        ...patientLabsReducers,
        ...stagedNoteReducers,
        ...returnToHospitalReducers,
        ...specialtiesReducers,
        ...hospiceIntakeReducers,
    },
});

export const {
    setPatient,
    setVisitDues,
    setSearchResults,
    setPatientSearchLoading,
    setPatientSearchVisible,
    updateCensusRoomNumber,
    updateCensus,
    updateUnacknowledgedCount,
    setPccPatientLoading,
    setCensusField,
    setVisit,
    setVisits,
    resetVisits,
    setPatientIntake,
    setCurrentStagedNote,
    clearVisitDuesForIntake,
    setRecentLabTestDetails,
    setCreateIntakeOnDischarge,
    setFacilityQuickNotes,
} = censusSlice.actions;

const getFilter = (filters?: CensusFiltersDto) => {
    if (filters == null) {
        filters = CensusFiltersDto.fromJS({
            isNewPatient: true,
            isActive: true,
            isDischarged: false,
            regions: [],
            locations: [],
        });
    }

    return filters;
};

export function fetchGridData(group: CensusGroupDto): AppThunk {
    return async (dispatch: AppDispatch) => {
        await dispatch(fetchBadgeData(group));
        await dispatch(fetchVisitDues(group));
    };
}

export function refreshCensus(patientIntakeId?: number, forceRefresh = false): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { censusFilters, currentNote, censusGroupsToRefresh, currentCensus, currentPatientIntake } = getState().census;

        // If note / chart is open - do not refresh census
        if (!forceRefresh && (currentNote || currentCensus || currentPatientIntake)) {
            return;
        }

        const censusGroups = await dispatch(fetchCensus(censusFilters));

        // Refresh badges/visit dues for each active group
        if (forceRefresh || censusGroupsToRefresh == null) {
            const activePanel = getStoredCollapsedState([LocalStoragePageKeys.census]);
            const activePanelId = parseInt(activePanel ?? '0');

            censusGroups.forEach((group) => {
                if (group.admittedToId === activePanelId) {
                    dispatch(fetchGridData(group));
                }
            });
        } else if (censusGroupsToRefresh != null) {
            censusGroupsToRefresh.forEach((censusGroup) => {
                const group = { ...censusGroup } as CensusGroupDto;

                if (patientIntakeId && !group.patientIntakeIds.includes(patientIntakeId)) {
                    // If the incoming intake needs added, do it here so the badge/visit dues get the latest data
                    group.patientIntakeIds = [...group.patientIntakeIds, patientIntakeId];
                }

                dispatch(fetchGridData(group));
            });
        }
    };
}

export function fetchCensus(filters?: CensusFiltersDto): AppThunk<Promise<CensusGroupDto[]>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { facilities, regions } = getState().admin;
        const client = new CensusClient(null, Axios);

        try {
            if (filters == null) {
                const savedFilters = await dispatch(fetchFilter('census-filter', getFilter()));

                if (savedFilters != null && savedFilters !== '') {
                    filters = CensusFiltersDto.fromJS(savedFilters);
                }
            }

            filters = getFilter(filters);

            await dispatch(setCensusFilters(filters, 'census-filter'));

            const response = await client.grouped(filters);

            if (response.result.succeeded) {
                if (facilities == null || facilities.length === 0) {
                    await dispatch(fetchFacilities()); // We need these for logic in the provider dropdown select change
                }

                if (regions == null || regions.length === 0) {
                    await dispatch(fetchRegions()); // We need these for logic in the publish schedule component
                }

                dispatch(censusSlice.actions.setCensus(response.result.entity));

                await dispatch(fetchTotalAbandoned());

                return response.result.entity;
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return [];
    };
}

export function fetchCensusByPatientIntakeId(admissionId: number, loadDrawer = true): AppThunk<Promise<CensusDto>> {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const client = new CensusClient(null, Axios);

        try {
            const response = await client.getByPatientIntakeId(admissionId);

            if (response.result.succeeded) {
                if (response.result.entity.patientId) {
                    if (loadDrawer) {
                        await dispatch(togglePatientDrawer(response.result.entity));
                    } else {
                        dispatch(setCurrentCensus(response.result.entity));
                    }
                }
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            return response.result.entity;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return null;
    };
}

export function setCensusFilters(filters: CensusFiltersDto, filterKey?: string, ignoreSet = false): AppThunk {
    return async (dispatch: AppDispatch) => {
        try {
            if (filterKey != null) {
                await dispatch(saveFilter(filterKey, filters));
            }

            if (!ignoreSet) {
                dispatch(censusSlice.actions.setCensusFilters(filters));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchVisitsByAdmission(dto: VisitDto): AppThunk {
    return async (dispatch: AppDispatch) => {
        const client = new SchedulesClient(null, Axios);

        // Don't run this logic for intakes
        if (!dto.patientIntakeId) {
            return;
        }

        try {
            const response = await client.getVisitsByPatientIntake(dto);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setVisits(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchBadgeData(censusGroup?: CensusGroupDto): AppThunk {
    return async (dispatch: AppDispatch) => {
        const client = new CensusClient(null, Axios);

        // Don't run this logic for collapses
        if (!censusGroup) {
            return;
        }

        try {
            dispatch(censusSlice.actions.storeCensusGroup(censusGroup));

            const dto = CensusBadgeRequestDto.fromJS({
                patientIntakeIds: censusGroup.patientIntakeIds,
            });

            const response = await client.getBadgeData(dto);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setBadgeData(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            await dispatch(fetchSpecialties(censusGroup.patientIntakeIds));
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchVisitDues(censusGroup?: CensusGroupDto): AppThunk {
    return async (dispatch: AppDispatch) => {
        const client = new CensusClient(null, Axios);

        // Don't run this logic for collapses
        if (!censusGroup) {
            return;
        }

        try {
            dispatch(censusSlice.actions.storeCensusGroup(censusGroup));

            const dto = RequiredVisitRequestDto.fromJS({
                facilityId: censusGroup.admittedToId,
                patientIntakeIds: censusGroup.patientIntakeIds,
                includeDetails: false,
                alwaysSetVisitType: false,
            });

            const response = await client.getVisitDues(dto);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setVisitDues(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function fetchVisitDuesForIntake(admittedToId: number, patientIntakeId: number): AppThunk<Promise<RequiredVisitResultDto[]>> {
    return async (dispatch: AppDispatch) => {
        const client = new CensusClient(null, Axios);

        try {
            const dto = RequiredVisitRequestDto.fromJS({
                facilityId: admittedToId,
                patientIntakeIds: [patientIntakeId],
                includeDetails: true,
                alwaysSetVisitType: false,
            });

            const response = await client.getVisitDues(dto);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setVisitDues(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }

            return response.result.entity;
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }

        return null;
    };
}

export function readmitPatient(undoDischarge: UndoDischargeDto): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { censusFilters } = getState().census;
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.undoDischarge(undoDischarge);

            if (response.result.succeeded) {
                await dispatch(fetchCensus(censusFilters));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function updatePatientDisposition(dischargeDisposition: DischargeDispositionChangeDto): AppThunk {
    return async (dispatch: AppDispatch) => {
        const client = new PatientIntakesClient(null, Axios);

        try {
            const response = await client.updateDischargeDisposition(dischargeDisposition);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.updateCensus(response.result.entity));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function refreshCensusItem(census: CensusDto): AppThunk {
    return async (dispatch: AppDispatch) => {
        const client = new CensusClient(null, Axios);

        try {
            const response = await client.getByPatientIntakeId(census.patientIntakeId);

            if (response.result.succeeded) {
                dispatch(censusSlice.actions.setCurrentCensus(response.result.entity));

                await dispatch(fetchPccPatient(census.patientIntakeId));

                if (response.result.entity.patientId) {
                    await dispatch(fetchPatient(response.result.entity.patientId));
                }
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export function setPendingMedicalSummaryChanges(dto: MedicalSummaryDto): AppThunk {
    return async (dispatch: AppDispatch, getState: () => RootState) => {
        const { account } = getState().auth;
        const { currentPatientIntake } = getState().census;

        if (dto.patientIntakeId === currentPatientIntake?.id && dto.lastEditedByUserId !== account.localAccountId) {
            const msFields = getCleanMedicalSummaryFields(dto);

            dispatch(censusSlice.actions.setPendingMedicalSummaryChanges(msFields));
        }
    };
}

export function clearPendingMedicalSummaryChanges(): AppThunk {
    return async (dispatch: AppDispatch) => {
        dispatch(censusSlice.actions.setPendingMedicalSummaryChanges(null));
    };
}

export function fetchNoteData(census: CensusDto): AppThunk {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPatient(census.patientId));

        if (census.isPatientPccLinked) {
            dispatch(fetchPccPatient(census.patientIntakeId));
        }
    };
}

export function fetchNotePageData(noteId: number): AppThunk {
    return async (dispatch: AppDispatch) => {
        const client = new NotesClient(null, Axios);

        try {
            dispatch(clearNoteData());

            const response = await client.getFormData(noteId);

            if (response.result.succeeded) {
                const data = response.result.entity;

                dispatch(setPatient(data.patient));
                dispatch(setCurrentNote(data.note));
                dispatch(setCurrentNoteType(data.note.noteType));
                dispatch(setCurrentCensus(data.census));
                dispatch(setPatientIntake(data.patientIntake));
                dispatch(applyPatientIntakeLock(data.medicalSummaryLockStatus));
                dispatch(setVisits(data.intakeVisits));

                dispatch(censusSlice.actions.setPccPatient(data.pccPatient));
                dispatch(censusSlice.actions.setPatient(data.patient));
                dispatch(censusSlice.actions.setBadgeData(data.badgeData));
                dispatch(censusSlice.actions.setVisitDues(data.visitDues));
                dispatch(censusSlice.actions.setRecentLabs(data.recentLabs));

                if (!data.note.signedTimestamp) {
                    // Allow the change note type button to function as it used to
                    dispatch(setCurrentUnsignedNote(data.note));
                    dispatch(setCurrentUnsignedNoteType(data.note.noteType));
                }

                // TODO: Move to tab to load on demand?
                dispatch(fetchPatientNotes(data.census.patientId));
                //dispatch(fetchRecentLabResults(data.census.patientId));
                //dispatch(fetchPatientDocumentGroups(data.census.patientId));
            } else {
                handleError(response, () => dispatch(censusSlice.actions.setError(null)));
            }
        } catch (error) {
            handleError(error, () => dispatch(censusSlice.actions.setError(error.toString())));
        }
    };
}

export const selectError = (state: RootState) => state.census.errorMessage;
export const selectCensus = (state: RootState) => state.census.census;
export const selectCensusFilters = (state: RootState) => state.census.censusFilters;
export const selectSchedule = (state: RootState, patientIntakeId: number) => state.census.schedules.find((x) => x.patientIntakeId === patientIntakeId);
export const selectPendingMedicalSummaryChanges = (state: RootState) => state.census.pendingMedicalSummaryChanges;
export const selectPendingMedicalSummaryFields = (state: RootState) => state.census.pendingMedicalSummaryFields;

export const selectCensusItemVisit = (state: RootState, census: CensusDto) => {
    if (!census || !state.auth?.account) {
        return null;
    }

    const { localAccountId } = state.auth.account;
    const { currentNote } = state.census;

    // If we pass a note, lets get the visit tied to that notes service date
    const serviceDate = currentNote?.serviceDate ?? moment();

    return state.census.visits.find((y) => {
        return (
            y.patientIntakeId === census.patientIntakeId &&
            y.providerId === localAccountId &&
            // Only return todays visits
            (y.serviceDate.isSame(serviceDate, 'day') || y.serviceDate.format('L') === serviceDate.format('L'))
        );
    });
};

export const selectCensusItemOtherVisits = (state: RootState, census: CensusDto) => {
    if (!census || !state.auth?.account) {
        return null;
    }

    const { localAccountId } = state.auth.account;
    const { currentNote } = state.census;

    // If we pass a note, lets get the visit tied to that notes service date
    const serviceDate = currentNote?.serviceDate ?? moment();

    return state.census.visits.filter((y) => {
        return (
            y.patientIntakeId === census.patientIntakeId &&
            y.providerId !== localAccountId &&
            // Only return todays visits
            (y.serviceDate.isSame(serviceDate, 'day') || y.serviceDate.format('L') === serviceDate.format('L'))
        );
    });
};

export const selectVisitButtons = (state: RootState, census: CensusDto) => {
    if (!census) {
        return null;
    }

    return state.census.visitButtons.find((y) => y.patientIntakeId === census.patientIntakeId);
};

export const visitDueSelectors = visitDuesAdapter.getSelectors();
export const censusBadgeSelectors = censusBadgesAdapter.getSelectors();
export const specialtiesSelectors = specialtiesAdapter.getSelectors();

export default censusSlice.reducer;
