import { useRef, useState } from 'react';
import { Alert, Col, Descriptions, Modal, Row, Spin, Table, Tag } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { RequiredVisitResultDto, VisitDueDateRecord } from '@medone/medonehp-api-client';
import classNames from 'classnames';
import moment from 'moment';

import { selectRequiredVisitTypes, selectVisitTypes } from '../../../../../../shared/common/data/slice';
import { formatDateTime, isInRole } from '../../../../../../shared/common/helpers';
import { useAppDispatch, useAppSelector } from '../../../../../../shared/hooks';
import { clearVisitDuesForIntake, fetchVisitDuesForIntake, visitDueSelectors } from '../../slice';
import { Role } from '../../../../../../shared/common/auth/RoleAuth';
import { AuthState } from '../../../../../../shared/common/auth/models';
import { selectAuth } from '../../../../../../shared/common/auth/slice';

type Props = {
    admissionDate: moment.Moment;
    admittedToId: number;
    patientIntakeId: number;
    isMobile?: boolean;
};

const { Column } = Table;

const noPaddingList = { marginBottom: 0, paddingInlineStart: '15px' };

const VisitDueBadge = ({ admissionDate, admittedToId, patientIntakeId, isMobile }: Props) => {
    const dispatch = useAppDispatch();
    const auth = useAppSelector<AuthState>(selectAuth);
    const visitDue = useAppSelector((state) => visitDueSelectors.selectById(state.census.visitDues, patientIntakeId));
    const visitTypes = useAppSelector(selectVisitTypes);
    const providerVisitTypes = useAppSelector(selectRequiredVisitTypes);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const visitDueDetails = useRef<RequiredVisitResultDto>();

    const handleClickBadge = async () => {
        setLoading(true);

        visitDueDetails.current = null;

        let response = await dispatch(fetchVisitDuesForIntake(admittedToId, patientIntakeId));

        if (response && response.length > 0) {
            visitDueDetails.current = response[0];
        }

        setLoading(false);
        setShowDetails(true);
    };

    const handleClose = () => {
        dispatch(clearVisitDuesForIntake(patientIntakeId));

        visitDueDetails.current = null;

        setShowDetails(false);
    };

    const renderVisitType = (value) => {
        return visitTypes?.find((x) => x.id === value)?.name;
    };

    const renderProviderVisitType = (value) => {
        return providerVisitTypes?.find((x) => x.id === value)?.name;
    };

    const renderVisitDetails = (record: VisitDueDateRecord) => {
        return (
            record &&
            record.visit && (
                <>
                    {record.visit.signedByUserName} on {formatDateTime(record.visit.date, '', 'L')}
                </>
            )
        );
    };

    const renderNextVisitDueDate = (data: moment.Moment) => {
        if (data != null && data.isValid() && data.year() === 9999) {
            return '---';
        }

        return formatDateTime(data, 'N/A');
    };

    const renderDetails = () => {
        return (
            visitDueDetails.current?.dueDates && (
                <Modal
                    title="Regulatory Information"
                    open={showDetails}
                    width="80%"
                    destroyOnClose
                    onCancel={handleClose}
                    footer={null}
                    className="regulatory-details"
                    style={{ top: 45 }}
                >
                    <Descriptions bordered className="mb-2">
                        <Descriptions.Item label="Admission Date">{formatDateTime(admissionDate, '', 'L')}</Descriptions.Item>
                        <Descriptions.Item label="Next Visit Due In">{renderVisitDue(visitDueDetails.current.overDueInDays, false)}</Descriptions.Item>
                        <Descriptions.Item label="Next Visit Due By">{renderNextVisitDueDate(visitDueDetails.current.nextVisitDueDate)}</Descriptions.Item>
                        <Descriptions.Item label="Visit Type">{renderVisitType(visitDueDetails.current.visitType)}</Descriptions.Item>
                        <Descriptions.Item label="Provider Type">{renderProviderVisitType(visitDueDetails.current.providerVisitType)}</Descriptions.Item>
                        <Descriptions.Item label="Admission H&P Required?">{visitDueDetails.current.ignoreRequiredHpVisit ? 'No' : 'Yes'}</Descriptions.Item>
                        <Descriptions.Item label="How did we get the visit due?">{visitDueDetails.current.reason}</Descriptions.Item>
                    </Descriptions>

                    {visitDueDetails.current.dueDates && visitDueDetails.current.dueDates.length > 0 && (
                        <div className="details-table">
                            <Row gutter={20}>
                                <Col span={20}>
                                    <Alert
                                        type="info"
                                        className="mb-2"
                                        message={
                                            visitDueDetails.current.ignoreRequiredHpVisit ? (
                                                <ul style={noPaddingList}>
                                                    <li>
                                                        Due to <strong>Admission H&P Required</strong> being disabled - The first 3 visits must be a Follow-Up done in the first 100
                                                        days by a Physician.
                                                    </li>
                                                    <li>After this (or after 100 days) the last visit due will be based on the last signed Note (Every 60 days).</li>
                                                </ul>
                                            ) : (
                                                <ul style={noPaddingList}>
                                                    <li>Physician must do an H&P for the intake, and then 2 additonal Follow-Ups.</li>
                                                    <li>The overdue date will count up until an H&P is completed by a Physician.</li>
                                                    <li>After this (or after 100 days) the last visit due will be based on the last signed Note (Every 60 days).</li>
                                                </ul>
                                            )
                                        }
                                    />
                                </Col>

                                <Col span={4} className="text-right">
                                    <small className="table-legend">
                                        <div className="no-visit">No / Missed visit</div>
                                        <div className="has-visit">Valid Visit, expand for details</div>
                                        <div className="due-visit">Next Visit Due</div>
                                    </small>
                                </Col>
                            </Row>

                            <Table
                                bordered
                                size="small"
                                rowKey={(x) => x.visitNumber}
                                pagination={false}
                                dataSource={visitDueDetails.current.dueDates}
                                scroll={{ y: 'calc(100vh - 400px)' }}
                                onRow={(record) => {
                                    const isNextDue = visitDueDetails.current.nextVisitDueDate?.isSame(record.date);
                                    const classes = classNames({
                                        'has-visit': record.visit != null,
                                        'no-visit': record.visit == null && !isNextDue,
                                        'due-visit': isNextDue,
                                    });

                                    return {
                                        className: classes,
                                    };
                                }}
                            >
                                <Column<VisitDueDateRecord> title="#" dataIndex="visitNumber" key="visitNumber" width="40px" className="text-center" />
                                <Column<VisitDueDateRecord> title="Visit Date" dataIndex="date" key="date" render={(data: moment.Moment) => formatDateTime(data)} width="150px" />
                                <Column<VisitDueDateRecord>
                                    title="Provider Type"
                                    dataIndex="requiredVisitType"
                                    key="visitType"
                                    render={(data) => renderProviderVisitType(data)}
                                    width="150px"
                                />
                                <Column<VisitDueDateRecord> title="Visit Type" dataIndex="visitType" key="visitType" render={(data) => renderVisitType(data)} width="150px" />
                                <Column<VisitDueDateRecord> title="Details" key="visitDetails" render={(data) => renderVisitDetails(data)} />
                            </Table>
                        </div>
                    )}
                </Modal>
            )
        );
    };

    const renderVisitDue = (overDueInDays?: number, handleClick = true) => {
        let color;

        if (overDueInDays < 0) {
            color = '#f1c40f';
        } else if (overDueInDays <= 10 && overDueInDays >= 1) {
            color = '#F09920';
        } else {
            color = '#cf142b';
        }

        let clickProps = null;
        const style = { display: overDueInDays ? 'flex' : '' };

        if (handleClick && isInRole(auth.permissions, [Role.SYSADMIN, Role.POST_ACUTE_ADMIN])) {
            style['cursor'] = 'pointer';

            clickProps = {
                onClick: handleClickBadge,
            };

            if (overDueInDays == null) {
                return (
                    <Spin spinning={isLoading}>
                        <QuestionCircleTwoTone className="visit-due" twoToneColor="#ccc" style={{ display: 'flex', cursor: 'pointer', fontSize: 30 }} {...clickProps} />
                    </Spin>
                );
            }
        }

        return (
            overDueInDays != null && (
                <Spin spinning={isLoading}>
                    <Tag color={color} className="visit-due" style={style} {...clickProps}>
                        {overDueInDays}
                    </Tag>
                </Spin>
            )
        );
    };

    return visitDue ? (
        <>
            {renderVisitDue(visitDue.overDueInDays, !isMobile)}

            {renderDetails()}
        </>
    ) : (
        <></>
    );
};

export default VisitDueBadge;
