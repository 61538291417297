import { useState } from 'react';
import { Modal, Row, Spin } from 'antd';

import { formatDateTime, noteTypes } from '../../../../../../shared/common/helpers';
import { useAppDispatch, useAppSelector } from '../../../../../../shared/hooks';
import { visitDueSelectors } from '../../slice';
import { fetchNote, selectNote, setCurrentNote } from '../../slice.notes';
import NotePreview from '../note-preview';
import { NoteDto } from '@medone/medonehp-api-client';

type Props = {
    patientIntakeId: number;
};

const MdLastVisitedBadge = ({ patientIntakeId }: Props) => {
    const dispatch = useAppDispatch();
    const visitDue = useAppSelector((state) => visitDueSelectors.selectById(state.census.visitDues, patientIntakeId));
    const latestNote = useAppSelector<NoteDto>(selectNote);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const handleVisibleChange = async (visible: boolean) => {
        setIsVisible(visible);
        setIsProcessing(true);

        if (visible) {
            await dispatch(fetchNote(visitDue.mdLastVisited?.id));
        } else {
            dispatch(setCurrentNote(null));
        }

        setIsProcessing(false);
    };

    const renderLastVisitedTitle = () => {
        if (!visitDue.mdLastVisited) {
            return 'Loading...';
        }

        const noteType = noteTypes.find((x) => x.value === visitDue.mdLastVisited.noteType);

        if (!noteType) {
            return 'Loading...';
        }

        return `Note Preview - ${noteType.name} on ${formatDateTime(visitDue.mdLastVisited.date)} by ${visitDue.mdLastVisited.signedByUserName}`;
    };

    return visitDue && visitDue.mdLastVisited && visitDue.mdLastVisited.date.isValid() ? (
        <>
            <Row className="last-visited-date" onClick={() => handleVisibleChange(true)}>
                {formatDateTime(visitDue.mdLastVisited.date)}
            </Row>

            <Modal
                width="50%"
                destroyOnClose
                open={isVisible}
                title={renderLastVisitedTitle()}
                onCancel={() => handleVisibleChange(false)}
                confirmLoading={isProcessing}
                footer={null}
            >
                <Spin spinning={isProcessing}>
                    <NotePreview note={latestNote} />
                </Spin>
            </Modal>
        </>
    ) : (
        <></>
    );
};

export default MdLastVisitedBadge;
