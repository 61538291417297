import { useState } from 'react';
import { Modal, Row, Spin } from 'antd';
import { NoteDto } from '@medone/medonehp-api-client';

import { formatDateTime, noteTypes } from '../../../../../../shared/common/helpers';
import { useAppDispatch, useAppSelector } from '../../../../../../shared/hooks';
import { censusBadgeSelectors } from '../../slice';
import { fetchNote, setCurrentNote } from '../../slice.notes';

import NotePreview from '../note-preview';

type Props = {
    latestNote: NoteDto;
    patientIntakeId: number;
};

const LastVisitedBadge = ({ latestNote, patientIntakeId }: Props) => {
    const dispatch = useAppDispatch();
    const censusBadge = useAppSelector((state) => censusBadgeSelectors.selectById(state.census.badges, patientIntakeId));
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const handleVisibleChange = async (visible: boolean) => {
        setIsVisible(visible);
        setIsProcessing(true);

        if (visible) {
            await dispatch(fetchNote(censusBadge?.latestSignedNoteId));
        } else {
            dispatch(setCurrentNote(null));
        }

        setIsProcessing(false);
    };

    const renderLastVisitedTitle = () => {
        if (!latestNote) {
            return 'Loading...';
        }

        const noteType = noteTypes.find((x) => x.value === latestNote.noteType);

        if (!noteType) {
            return 'Loading...';
        }

        return `Note Preview - ${noteType.name} on ${formatDateTime(latestNote.serviceDate)} by ${latestNote.signedByUserName}`;
    };

    return (
        <>
            {censusBadge != null &&
                censusBadge.latestSignedNoteId != null &&
                censusBadge.latestSignedNoteServiceDate != null &&
                censusBadge.latestSignedNoteServiceDate.isValid() && (
                    <>
                        <Row className="last-visited-date" onClick={() => handleVisibleChange(true)}>
                            {censusBadge.latestSignedNoteServiceDate.format('L')}
                        </Row>

                        <Modal
                            width="50%"
                            destroyOnClose
                            open={isVisible}
                            title={renderLastVisitedTitle()}
                            onCancel={() => handleVisibleChange(false)}
                            confirmLoading={isProcessing}
                            footer={null}
                        >
                            <Spin spinning={isProcessing}>
                                <NotePreview note={latestNote} />
                            </Spin>
                        </Modal>
                    </>
                )}
        </>
    );
};

export default LastVisitedBadge;
